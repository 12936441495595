<template> 
    <div id="login">
		<div class="back">
			<aside>
				<img src="../../assets/flogo.png" width="200" height="200" style="margin-top: -50px" alt="">	
				<form style="margin-top: -50px">
					<div class="access_social">
						<!-- <a href="#0" class="social_bt facebook">Login with Facebook</a> -->
						<a :href="googleSignInURL" class="social_bt google" v-if="googleSignInURL!=''&&!GoogleLoading"><img src="https://img.icons8.com/color/16/000000/google-logo.png"> Login with Google</a>
						<!-- <a href="#0" class="social_bt linkedin">Login with Linkedin</a>  -->
					</div>
					<div class="divider"><span>Or</span></div>
					<div class="form-group">
						<span class="input">
						<input class="input_field" type="email" autocomplete="off" name="email" v-model="email">
							<label class="input_label">
								Your email
							</label>
						</span>

						<span class="input">
						<input class="input_field" type="password" autocomplete="new-password" name="password" v-model="password">
							<label class="input_label">
								Your password
							</label>
						</span>
						<small><router-link :to="'/passwordRequest'"><a >Forgot password?</a></router-link></small>
					</div>
					<Loading v-if="Loading" />
					<Loading v-if="GoogleLoading" />
					<Errors v-if="error">
						<ul>
							<li v-for="error in error" :key="error">{{error}}</li>	
						</ul>
					</Errors>
					<div @click.prevent="loginUser()" v-if="!Loading&&!GoogleLoading"><button class="myButtoonn">Login to faslearn</button></div>
					<router-link :to="'/register'">
					<div class="text-center add_top_10">New to Faslearn? <strong><a >Sign up!</a></strong></div>
					</router-link>
				</form>
				<div class="copy">© 2021 Faslearn</div>
			</aside>
		</div>
	</div>
	<!-- /login -->
</template>

<script>
    // import Nav from '@/components/site/Nav.vue'
	// import Tittle from '@/components/site/Tittle.vue'
	import Loading from '@/components/Loading.vue'
	import Errors from '@/components/Errors.vue'
	import { mapActions, mapGetters } from 'vuex';
    export default {
		name: 'Login',
		data(){
			return{
				email: '',
				password: '',
				Loading: '',
				GoogleLoading: false,
				googleSignInURL: '',
				googleCode: '',
				affiliateRef: localStorage.getItem('fmrAffiliateUserRef'),
				//error: ''
			}
		},
		computed: {
			...mapGetters(['error','publicToken'])
		},
		components: {
			Errors,
			Loading
		},
        mounted(){
			this.googleCode = this.$route.query.code
			if (this.googleCode!=''&&this.googleCode!=null) {
				this.GoogleLoading = true
				this.googleSignIn__public({code: this.googleCode, publicToken: this.publicToken, affiliateRef: this.affiliateRef})
				.then(res => {
					console.log(res.data)
					if (res.data.success == true) {
						if(localStorage.getItem('fmrlandingpage')==''||localStorage.getItem('fmrlandingpage')==null){
							let str = window.location.href+"";
							const myArr = str.split("/logi");
							window.location.href = myArr[0] + "/dashboard"
						}else{
							let redirectURL = localStorage.getItem('fmrlandingpage')
							localStorage.removeItem('fmrlandingpage')
							window.location.href = redirectURL
						}
						this.GoogleLoading = false
						localStorage.removeItem('fmrAffiliateUserRef')
					}
				})
			}
			this.reset_error(),
			this.getGoogleSignInUrl__public()
			.then(res => {
				this.googleSignInURL = res.data
			})
		},
		methods:{
			...mapActions(['login','reset_error','getGoogleSignInUrl__public','googleSignIn__public']),
			loginUser() {
				this.Loading = true
				let user = {
					email: this.email,
					password: this.password
				};
				this.login(user)
				.then(res => {
					this.Loading = false
					if (res.status == 200) {
						//this.$router.push('/');
						//window.location.href = process.env.VUE_APP_CLIENT_URL + "dashboard"
						if(localStorage.getItem('fmrlandingpage')==''||localStorage.getItem('fmrlandingpage')==null){
							let str = window.location.href+"";
							const myArr = str.split("/logi");
							window.location.href = myArr[0] + "/dashboard"
						}else{
							let redirectURL = localStorage.getItem('fmrlandingpage')
							localStorage.removeItem('fmrlandingpage')
							window.location.href = redirectURL
						}
					}
					// this is supposed to redirect to /dashboard but due to change of template, the dashborad headders  still sees the site header causing some errors with documrnt.getElementbyid try 
				})
				// .catch(err => {
				// 	//this.error = err;
				// });
			}
		}
    }
</script>

<style>
	h4{
		display: none;
	}
	.back{
		width: 100%;
		height: 750px;
		background-color: red;
		background: url(../../assets/12.jpg) center center no-repeat fixed;
	}
	.myButtoonn{
		background-color: #EF6421;
		width: 100%;
		margin: 5px; 
		color: white;
		padding: 10px;
		padding-left: 30px;
		padding-right: 30px;
		border-radius: 20px;
		border: none;
	}
</style>