<template>
    <div class="alert alert-danger">
        <center>
           <img src="./assets/error.png" width="100" alt="">
        <slot /> 
        </center>
        
    </div>
</template>

<script>
export default {
    name: 'Errors'
}
</script>

<style scoped>
.alert{
    border-radius: 3px;
}
.alert-danger{
    background: transparent;
    color: grey;

}
</style>